<div id="main">
    <div id="container-1" class="container">
        <div id="container-2" class="container">
            <div id="container-3" class="container" fusePerfectScrollbar
                 [fusePerfectScrollbarOptions]="{suppressScrollX: true, updateOnRouteChange : true}">
                <!-- CONTENT -->
                <content></content>
                <!-- / CONTENT -->
            </div>
        </div>
    </div>
</div>
